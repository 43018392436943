import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Meme from './components/Meme';
import Test from './components/Test';
import Form from './components/Form';

function App() {
  return (
    <div className="App">
     <Header />
     <Meme/>
  
    </div>
  );
}

export default App;
